import React from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/Layout";
import { MONTH_NAMES, DAY_NAMES } from "../../CONSTANTS";

import "../../styles/pages/contact.scss";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

function formatDate(date) {
  const d = date.getDate();
  const ddd = DAY_NAMES[date.getDay()];
  const yyyy = date.getFullYear();
  const mmm = MONTH_NAMES[date.getMonth()];
  const o = (() => {
    if (d === 1 || d === 21 || d === 31) {
      return "st";
    }
    if (d === 2 || d === 22) {
      return "nd";
    }
    if (d === 3 || d === 23) {
      return "rd";
    }
    return "th";
  })();

  // Monday 1st June 2022
  return `${ddd} ${d}${o} ${mmm} ${yyyy}`;
}

const formFields = [
  {
    label: "Name",
    name: "name",
    required: true,
  },
  {
    label: "Email Address",
    name: "email",
    type: "email",
    required: true,
  },
  {
    label: "Phone Number",
    name: "phone",
    type: "tel",
  },
  {
    label: "Date of Wedding",
    name: "date",
    type: "date",
    required: true,
  },
  {
    label: "Wedding Venue",
    name: "venue",
  },
  {
    label: "Ceremony Time",
    name: "ceremony",
  },
  {
    label: "Photographer",
    name: "photographer",
  },
  {
    label: "Other Suppliers",
    name: "otherSuppliers",
  },
  {
    label: "Message",
    name: "message",
    required: true,
    textarea: true,
  },
  {
    label: "How did you hear about me?",
    name: "source",
  },
];

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const { date } = this.state;
    const formattedDate = formatDate(new Date(date));

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
        date: formattedDate,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <h1>Contact</h1>
              <p>
                Thank you for visiting my website and taking the time to contact
                me. Here's a short contact form for you to complete and I'll get
                back to you ASAP, usually well within 24 hours. Please make sure
                to check you SPAM/JUNK folder if you haven't had a reply within
                24 hours.
              </p>
              <form
                name="contact"
                method="post"
                action="/contact/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact" />
                <div hidden>
                  <label>
                    Don’t fill this out:{" "}
                    <input name="bot-field" onChange={this.handleChange} />
                  </label>
                </div>
                {formFields.map(
                  ({
                    label,
                    name,
                    type,
                    required = false,
                    textarea = false,
                  }) => (
                    <div key={name}>
                      <label htmlFor={name}>{label}</label>
                      <div>
                        {textarea ? (
                          <textarea
                            className="textarea"
                            name={name}
                            onChange={this.handleChange}
                            id={name}
                            required={required}
                            rows={4}
                          />
                        ) : (
                          <input
                            className="input"
                            type={type || "text"}
                            name={name}
                            onChange={this.handleChange}
                            id={name}
                            required={required}
                          />
                        )}
                      </div>
                    </div>
                  )
                )}

                <div className="field">
                  <button className="button is-link" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
